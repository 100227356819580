/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../../components/Layout'
import ThemeWrapper from '../../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Subtitle } from '@swp/components'
import SiteHeader from '../../components/hr/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Prázdna stránka"}>
        <SiteHeader />

        <Column className="css-d6fybz pb--60 pt--60" name={"uvod"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l13"} css={css`
        
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/i/template/146/fullscreen_s=3000x_.jpg);
    }
  
      `}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--center pb--25 pt--40" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--72" content={"<span style=\"color: rgb(255, 255, 255);\">Page title</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" name={"information"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":560}} content={"Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized. Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and maximum width of a block of text may be customized.Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized. "}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--40 pt--40" name={"footer"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-dominant);\">Click and start writing. This is a text area. If needed, the color, font size, line spacing, text alignment and even the <strong>maximum width of a block of text</strong> may be customized.  If you wish to find out more about editing, click <a href=\"https://saywebpage.com/cs/video-pruvodce-editaci/\">here</a>.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Subtitle className="subtitle-box" content={"<span style=\"color: var(--color-dominant);\">Professional</span>"}>
              </Subtitle>

              <Title className="title-box mt--02" content={"<span style=\"color: var(--color-dominant);\">Care for<br>Your Feet</span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-dominant);\">722 Finch Street, Asbury Park, NJ 07712<br>510-851-4014</span>"}>
              </Text>

              <Text className="text-box" content={"<span style=\"color: var(--color-dominant);\">ID: Property record keeping<br>Created with <a href=\"https://saywebpage.com\">saywebpage.com</a></span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}